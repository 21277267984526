import React, { useCallback, useMemo } from 'react'
import axios from 'axios'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@mui/material/styles'
import useLocalStorage from 'use-local-storage'
import logo from './cention-logo.svg'
import './App.css'
import { useHealth, useSyncCssVars, useWatch, useFetchUser } from './hooks'
import theme, { darkTheme } from './react-health-dashboard/theme'
import HealthdDashboard from './react-health-dashboard/react-health-dashboard'
import MaterialUISwitch from './DarkModeSwitch'

// Create a client
const queryClient = new QueryClient()

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AppInternal />
  </QueryClientProvider>
)

const filterInvert50 = { filter: 'invert(50%)' }
const noFilter = {}

const AppInternal = () => {
  const currentUser = useFetchUser()
  const { isLoading, data, error } = useHealth()
  const [dark, setDark] = useLocalStorage('dark', false)
  const toggleDarkMode = useCallback(() => { setDark(!dark) }, [dark, setDark])
  const currentTheme = useMemo(() => dark ? darkTheme : theme, [dark])
  const { mode } = currentTheme.palette
  const filter = useMemo(
    () => mode === 'dark' ? filterInvert50 : noFilter,
    [mode]
  )
  useSyncCssVars(currentTheme)
  const healthd = useWatch()

  return (
    <ThemeProvider theme={currentTheme}>
      <div className="App">
        <header className="App-header">
          <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: '1193px' }}>
            <LoginForm currentUser={currentUser} />
            <MaterialUISwitch checked={dark} onChange={toggleDarkMode} />
          </div>
          <HealthdDashboard
            data={healthd}
            loading={!healthd}
            currentUser={currentUser}
          />
          <img
            src={logo}
            style={filter}
            className="App-logo"
            alt="Cention Contact Center"
          />
          <p>
            Contact Center software - One Platform for all Communication.
          </p>
          <a
            className="App-link"
            href="https://cention.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
          <HealthStatus isLoading={isLoading} data={data} error={error} />
        </header>
      </div>
    </ThemeProvider>
  )
}

const LoginForm = ({ currentUser }) => {
  const [user, setLoginUser] = useLocalStorage('username', '')
  const [formValue, setformValue] = React.useState({
    username: '',
    password: ''
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    const loginFormData = {
      username: formValue.username,
      password: formValue.password
    }
    try {
      const { data, status } = await axios({
        method: 'post',
        url: '/signin',
        data: loginFormData,
        headers: { 'Content-Type': 'application/json' }
      })
      const { message, username } = data
      console.log(status, message, username)
      if (message === 'Login success' && username !== '') {
        setLoginUser(username)
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value
    })
  }

  const handleLogout = () => {
    setLoginUser('')
  }

  return (
    <div>
    {currentUser !== '' && currentUser === user
      ? (<div style={{ fontSize: '15px', paddingLeft: '385px' }}>
          {`Welcome ${currentUser}!`}
          <a href="/logout" onClick={handleLogout}
            style={{ fontSize: '12px', textDecoration: 'none', padding: '7px' }}>
              Logout
          </a>
        </div>
        )
      : (<div style={{ paddingLeft: '146px' }}>
          <form onSubmit = {handleSubmit}>
            <input
              type="username"
              name="username"
              placeholder="username"
              value={formValue.username}
              onChange={handleChange}
            />
            <input
              type="password"
              name="password"
              placeholder="password"
              value={formValue.password}
              onChange={handleChange}
            />
            <button type="submit">Login</button>
          </form>
        </div>
        )
    }
    </div>
  )
}

const HealthStatus = ({ isLoading, data, error }) => {
  if (isLoading) {
    return null
  }
  return (
    <p>
      <span>Health status: </span>
      <span>{data.status}</span>
      <Error text={error} />
    </p>
  )
}

const Error = ({ text }) => {
  if (!text) {
    return null
  }
  let t
  if (text.message) {
    t = text.message
  } else {
    t = 'Unknown error'
  }
  return <span> Error: {t}</span>
}

export default App
