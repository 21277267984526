import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import LinearProgress from '@mui/material/LinearProgress'
import HealthBar from './HealthBar'
import ErrorDisplay from './ErrorDisplay'
import { getter, looper, memo } from './utils'

const paperStyle = {
  width: '100%',
  marginTop: 3,
  overflowX: 'auto'
}

const tableStyle = {
  minWidth: 650
}

const HealthDashboard = ({ data, loading, currentUser, error }) => (
  <Paper sx={paperStyle}>
    {loading
      ? <LinearProgress />
      : error
        ? <ErrorDisplay error={error} />
        : (<Table sx={tableStyle} size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='left' />
                <TableCell align='center'>Servers</TableCell>
                <TableCell align='left'>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {looper(data).map(item => (
                <HealthBar
                  key={getter(item, 'name')}
                  component={item}
                  subComponents={getter(item, 'elements')}
                  currentUser={currentUser}
                />
              ))}
            </TableBody>
          </Table>
          )
    }
  </Paper>
)

export default memo(HealthDashboard)
