import { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { useQuery, useQueryClient } from 'react-query'
import { List, Map, is } from 'immutable'
import moment from 'moment'
import fetch from './fetch'
import { WatchRequest } from 'c3/protobufs/healthd/healthd_pb'
import { HealthdClient } from 'c3/protobufs/healthd/healthd_grpc_web_pb'
import mapValues from 'lodash/mapValues'

export const useSyncCssVars = theme => {
  useMemo(() => {
    const r = document.querySelector(':root')
    r.style.setProperty('--background', theme.palette.background.default)
    r.style.setProperty('--text-primary', theme.palette.text.primary)
    r.style.setProperty('--text-secondary', theme.palette.text.secondary)
    r.style.setProperty('--text-link', theme.palette.info.main)
  }, [theme])
}

export const useWatch = () => {
  const queryClient = useQueryClient()
  const [data, setData] = useState()
  useEffect(() => {
    const s = new HealthdClient('')
    const stream = s.watch(new WatchRequest())
    queryClient.setQueryDefaults(healthdKey, { cacheTime: Infinity })
    stream.on('data', response => {
      const data = response.toObject()
      data.data = JSON.parse(data.data)
      queryClient.setQueryData(healthdKey, healthdUpdater(data))
      setData(old => {
        const data = queryClient.getQueryData(healthdKey)
        return is(old, data) ? old : data
      })
    })
    stream.on('status', status => { console.log('rx metadata:', status) })
    stream.on('error', err => { console.log('stream error:', err) })
    stream.on('end', () => { console.log('stream end signal received') })
    return () => { stream.cancel() }
  }, [queryClient, setData])
  return data
}

const healthdKey = 'healthd'

const healthdUpdater = ({ c3region, checkd, data }) => old => {
  const k = healthDataKey(c3region, checkd)
  const elements = convertData(data)
  if (old && old.has(k)) {
    console.log('healthd updated data')
    return retainOldIfEqual(old, old.withMutations(m => {
      const inElements = [k, 'elements']
      m.mergeDeepIn(inElements, elements)
      elements.keySeq().forEach(elementKey => {
        const k = inElements.concat([elementKey])
        let points = m.getIn(k).get('datapoints')
        if (points.size > keepRecordSize) {
          points = points.skip(points.size - keepRecordSize)
          m.setIn(k.concat(['datapoints']), points)
        }
        m.mergeIn(k, reduceDatapoints(points))
      })
      const lastCheckTimeKey = [k, 'lastCheckTime']
      const { lastCheckTime, status } = reduceStatusAndCheckTime(
        m.getIn(lastCheckTimeKey),
        m.getIn(inElements)
      )
      m.setIn(lastCheckTimeKey, lastCheckTime)
      m.setIn([k, 'status'], status)
    }))
  }
  const { lastCheckTime, status } = reduceStatusAndCheckTime(0, elements)
  const m = {
    [k]: Map({
      name: `${checkd} (${c3region})`,
      status,
      lastCheckTime,
      datapoints: emptyList,
      elements
    })
  }
  if (old) {
    console.log('healthd new checkd data')
    return retainOldIfEqual(old, old.merge(m))
  }
  console.log('healthd fresh data')
  return Map(m)
}

const retainOldIfEqual = (old, newer) => is(old, newer) ? old : newer

const reduceStatusAndCheckTime = (lastCheckTime, elements) => {
  let count = 0
  const result = elements.reduce(
    (r, m) => {
      const lastCheckTime = m.get('lastCheckTime')
      if (lastCheckTime > r.lastCheckTime) {
        r.lastCheckTime = lastCheckTime
      }
      count++
      r.status += m.get('status')
      return r
    },
    { lastCheckTime, status: 0 }
  )
  result.status = result.status / count
  return result
}

const convertData = data => Map(mapValues(data, convertSingleData))

const convertSingleData = (data, id) => {
  const timestamp = convertStringToTimestamp(data.check_time)
  const value = statusToNum(data.status)
  return Map({
    id,
    name: data.name,
    lastCheckTime: timestamp,
    status: value,
    datapoints: List([Map({ timestamp, value })])
  })
}

const reduceDatapoints = points => {
  const total = points.reduce((r, m) => {
    r += m.get('value')
    return r
  }, 0)
  return { lastCheckTime: points.last().get('timestamp'), status: total / points.size }
}

const convertStringToTimestamp = date => moment(
  date,
  'YYYY-MM-DDTHH:mm:ss.SSSSSSSSSZZ'
).valueOf()

const statusToNum = status => {
  if (status === 'ok') {
    return 1
  } else if (status === 'failed') {
    return 0
  }
  return 0.5
}

const healthDataKey = (c3region, checkd) => `${checkd}.${c3region}`

const keepRecordSize = 10

export const useHealth = () => useQuery(
  'health',
  () => fetch('/health').then(response => response.json())
)

export const useFetchUser = () => {
  const { data } = useQuery('users', fetchUser)
  if (data !== undefined && data !== null) {
    return data.username
  }
  return ''
}

const fetchUser = () => {
  return axios.get('/username', {}).then(response => response.data)
}

const emptyList = List([])
