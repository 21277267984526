import { memo as _memo } from 'react'
import { is } from 'immutable'

export const getter = (o, key) => withImmutablejs ? o.get(key) : o[key]

export const looper = (o, sorter) => {
  if (withImmutablejs) {
    if (typeof sorter === 'function') {
      return o.sortBy(sorter).toList()
    }
    return o.toList()
  }
  return onbeforeunload
}

const withImmutablejs = true

export const memo = component => _memo(component, is)

const partialAccessItems = [
  { name: 'telavox', pattern: 'ind mail.fetch {cc-' },
  { name: 'bla bla', pattern: 'ind mail.fetch {blabla-' }
]

const noAccessItems = [
  { pattern: 'ind mail.fetch' },
  { pattern: 'outd mail.send' }
]

export const partialAccess = (name, content) => {
  var bool = false
  partialAccessItems.forEach((obj) => {
    if (obj.name === name) {
      if (noAccessWithoutLoggedin(content)) {
        if (content.includes(obj.pattern)) {
          bool = false
        }
        bool = true
      }
    }
  })
  return bool
}

export const noAccessWithoutLoggedin = (content) => {
  return noAccessItems.find(obj => {
    if (content.includes(obj.pattern)) {
      return true
    }
    return false
  })
}
