import { createTheme } from '@mui/material/styles'
import palette from './palette'
import typography from './typography'
import overrides from './overrides'

export const darkTheme = createTheme({ palette: { mode: 'dark' } })
export const lightTheme = createTheme({ palette: { mode: 'light' } })

const theme = createTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 100,
    drawer: 1200
  }
})

export default theme
