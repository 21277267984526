import React, { useMemo } from 'react'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import Tooltip from '@mui/material/Tooltip'

const StatusIcon = ({ status, title, style }) => {
  const s = useMemo(() => {
    let statusIconColor
    if (status === 1) {
      statusIconColor = '#258933'
    } else if (status === 0) {
      statusIconColor = '#ff0000'
    } else if (status > 0 && status < 1) {
      statusIconColor = '#ebc034'
    } else {
      statusIconColor = '#918f89'
    }
    return { color: statusIconColor, ...style }
  }, [status, style])
  return (
    <Tooltip title={title}>
      <FiberManualRecordIcon style={s} />
    </Tooltip>
  )
}

export default StatusIcon
