import React, { useCallback, useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ListItemIcon from '@mui/material/ListItemIcon'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import StatusIcon from './StatusIcon'
import { getter, looper, memo, partialAccess, noAccessWithoutLoggedin } from './utils'

const marginStyle = { margin: '8px 0px' }
const marginWidth = { width: '200px', margin: '0.1em' }
const marginSmallWidth = { width: '0.8em', margin: '0.1em' }
const width100Percent = { width: '100%' }
const widthOnePercent = { width: '1%' }
const veriticalTopAlign = { verticalAlign: 'top' }

const HealthBar = ({ component, subComponents, currentUser }) => {
  const [open, setOpen] = useState(false, true)
  const datapoints = getter(component, 'datapoints')
  const lastCheckTime = getter(component, 'lastCheckTime')
  const status = getter(component, 'status')
  return (
    <TableRow style={veriticalTopAlign}>
      <TableCell align='left' style={widthOnePercent}>
        <StatusIcon
          status={status}
          title={lastCheckTime ? new Date(lastCheckTime).toString() : ''}
          style={marginStyle}
        />
      </TableCell>
      <TableCell component='th' scope='row' style={width100Percent}>
        <ListItem
          button
          onClick={useCallback(() => { setOpen(open => !open) }, [])}
        >
          {open ? <ExpandLess /> : <ExpandMore />}
          <DenseListItemTxt primary={getter(component, 'name')} />
          <div>
            {datapoints && datapoints.map(item => (
              <StatusIcon
                key={item.timestamp}
                status={item.value}
                title={new Date(parseInt(item.timestamp)).toString()}
              />
            ))}
          </div>
        </ListItem>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {looper(subComponents, v => v.get('name')).map(subComponent => {
              const content = getter(subComponent, 'name')
              const checkTime = getter(subComponent, 'lastCheckTime')
              if (currentUser === '' && noAccessWithoutLoggedin(content)) {
                return null
              } else if (partialAccess(currentUser, content)) {
                return null
              } else {
                return (
                  <ListItem key={content}>
                    <ListItemIcon>
                      <StatusIcon
                        status={getter(subComponent, 'status')}
                        title={checkTime ? new Date(checkTime).toString() : ''}
                      />
                    </ListItemIcon>
                    <DenseListItemTxt primary={content} />
                    <div>
                      {getter(subComponent, 'datapoints').map(item => {
                        const timestamp = getter(item, 'timestamp')
                        return (
                          <StatusIcon
                            key={timestamp}
                            status={getter(item, 'value')}
                            title={new Date(parseInt(timestamp)).toString()}
                            style={marginSmallWidth}
                          />
                        )
                      })}
                    </div>
                  </ListItem>
                )
              }
            })}
          </List>
        </Collapse>
      </TableCell>
      <TableCell align='left'>
        {status === 1 ? 'Healthy' : 'Unhealthy'}
      </TableCell>
    </TableRow>
  )
}

const DenseListItemTxt = ({ primary }) => (
  <ListItemText
    primary={primary}
    size='small'
    dense='true'
    style={marginWidth}
  />
)

export default memo(HealthBar)
